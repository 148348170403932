const nav = document.getElementById("subocea-navigation");

const setNavClass = () => {
  if(nav) {
    if (
        document.body.scrollTop >= 200 ||
        document.documentElement.scrollTop >= 200
    ) {
      nav.classList.add("nav-filled");
/*      nav.classList.remove("nav-transparent");*/
    } else {
     /* nav.classList.add("nav-transparent");
      nav.classList.remove("nav-filled");*/
      nav.classList.add("nav-filled");
    }
  }

};
setNavClass();
window.onscroll = setNavClass
const burgerIcon = document.querySelector(".burger-icon");
const destinationsMobileMenu = document.getElementById("destinations-mobile");
const destinationsMobileMenuCloseButton = document.querySelector(
    "#destinations-mobile .close-button"
);

if (burgerIcon){
  burgerIcon.addEventListener("click", () => {
    destinationsMobileMenu.classList.toggle("hidden");
    document.querySelector("body").classList.toggle("noScroll");
  });
}

if (destinationsMobileMenuCloseButton){
  destinationsMobileMenuCloseButton.addEventListener("click", () => {
    destinationsMobileMenu.classList.toggle("hidden");
    document.querySelector("body").classList.toggle("noScroll");
  });
}



const destinationNavItem = document.querySelector("#destination-nav-item");
const destinationNavMenu = document.querySelector(".destination-nav-menu");
const whoNavItem = document.querySelector("#who-nav-item");
const whoNavMenu = document.querySelector(".who-nav-menu");


if (destinationNavItem){
  destinationNavItem.addEventListener("click", () => {
    destinationNavMenu.classList.toggle("hidden");
    whoNavMenu.classList.add("hidden");
  });
}

if (destinationNavMenu){
  destinationNavMenu.addEventListener("click", function (event) {
    var rect = destinationNavMenu.getBoundingClientRect();
    var isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      destinationNavMenu.classList.add("hidden");
    }
  });
}




function openRegion(regionName) {
  var i;
  var regions = document.getElementsByClassName("region");
  for (i = 0; i < regions.length; i++) {
    regions[i].style.display = "none";
  }
  document.getElementById("nav-menu-" + regionName).style.display = "flex";
}

//WHo are we


if (whoNavItem){
  whoNavItem.addEventListener("click", () => {
    whoNavMenu.classList.toggle("hidden");
    destinationNavMenu.classList.add("hidden");
  });

}

if (whoNavMenu){
  whoNavMenu.addEventListener("click", function (event) {
    var rect = whoNavMenu.getBoundingClientRect();
    var isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      whoNavMenu.classList.add("hidden");
    }
  });
}

function openLink(slug) {
  window.location.href=slug;
}




